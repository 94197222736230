import { Override } from "framer"

export const GoBack: Override = () => {
    return {
        onTap() {
            // Check if the history length is greater than 1
            if (window.history.length > 1) {
                window.history.back()
            } else {
                // Navigate to the UI kit page if there's no history to go back to
                window.location.href = `${window.location.origin}/`
            }
        },
    }
}
